/* You can add global styles to this file, and also import other style files */
@import "./assets/theme/custom-theme";
@import "../node_modules/bootstrap/scss/bootstrap.scss";

html,
body {
  height: 100%;
}

body {
  //overflow: hidden !important;
  margin: 0;
  //font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #fafafa;
}

button:focus {
  outline: none !important;
}


::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(210, 210, 210);
  border: 0px none #a4a4a4;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(190, 190, 190);
}

::-webkit-scrollbar-thumb:active {
  background-color: rgb(190, 190, 190);
}

::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #969696;
  border-radius: 100px;
}

::-webkit-scrollbar-track:hover {
  background: transparent;
}

::-webkit-scrollbar-track:active {
  background: transparent;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

